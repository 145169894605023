var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { id: "auth" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-slide-y-transition",
                {
                  attrs: { appear: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "heading",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "h1",
                              {
                                staticClass: "display-2 font-weight-bold mb-2",
                              },
                              [_vm._v("Register")]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "card" },
                    [
                      _c("figure", { staticClass: "btn-back" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icons/arrow_back_ios.svg"),
                            alt: "Botão voltar",
                          },
                          on: { click: _vm.goBack },
                        }),
                      ]),
                      _c(
                        "v-card-title",
                        { staticClass: "justify-center title-card" },
                        [_c("h2", [_vm._v("Crie uma conta!")])]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "flex-column",
                                  attrs: { align: "start" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start py-0",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-filelds" },
                                        [_vm._v("Nome")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input",
                                        attrs: {
                                          placeholder: "Jacob Jones",
                                          rules: [
                                            (v) =>
                                              !!v ||
                                              "Porfavor, insira seu nome",
                                          ],
                                          color: "secondary",
                                          required: "",
                                          outlined: "",
                                          rounded: "",
                                        },
                                        model: {
                                          value: _vm.name,
                                          callback: function ($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "flex-column tipo-cliente",
                                  attrs: { align: "start" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start py-0",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-filelds" },
                                        [_vm._v("Você é um:")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.tipoInvestidor,
                                        callback: function ($$v) {
                                          _vm.tipoInvestidor = $$v
                                        },
                                        expression: "tipoInvestidor",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          name: "active",
                                          label: "Investidor",
                                          value: 1,
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          name: "active",
                                          label: "Profissional",
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "flex-column",
                                  attrs: { align: "start" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start py-0",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-filelds" },
                                        [_vm._v("E-mail")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input",
                                        attrs: {
                                          placeholder:
                                            "willie.jennings@example.com",
                                          rules: [
                                            (v) => !!v || "E-mail requerido",
                                          ],
                                          color: "secondary",
                                          required: "",
                                          outlined: "",
                                          rounded: "",
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function ($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "flex-column senha",
                                  attrs: { align: "start" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start py-0",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-filelds" },
                                        [_vm._v("Senha")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input",
                                        attrs: {
                                          placeholder: "********",
                                          rules: _vm.passwordRules,
                                          color: "secondary",
                                          required: "",
                                          outlined: "",
                                          rounded: "",
                                          "append-icon": _vm.show
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show ? "text" : "password",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.show = !_vm.show
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.success
                            ? _c("div", { staticClass: "mensagem-sucesso" }, [
                                _c("span", [
                                  _vm._v("Usuário criado com sucesso"),
                                ]),
                              ])
                            : _vm._e(),
                          !_vm.success
                            ? _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("div", { staticClass: "condicoes" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.termosCondicoesLidos,
                                            expression: "termosCondicoesLidos",
                                          },
                                        ],
                                        staticClass: "checkBox",
                                        attrs: {
                                          type: "checkbox",
                                          name: "s",
                                          id: "checkBox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.termosCondicoesLidos
                                          )
                                            ? _vm._i(
                                                _vm.termosCondicoesLidos,
                                                null
                                              ) > -1
                                            : _vm.termosCondicoesLidos,
                                        },
                                        on: {
                                          click: function ($event) {
                                            !_vm.termosCondicoesLidos
                                          },
                                          change: function ($event) {
                                            var $$a = _vm.termosCondicoesLidos,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.termosCondicoesLidos =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.termosCondicoesLidos =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.termosCondicoesLidos = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(" Eu li e concordocom os "),
                                        _c(
                                          "span",
                                          { staticClass: "termos-condicoes" },
                                          [_vm._v("Termos e Condições")]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.success
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "my-4 btn-login py-3",
                                  attrs: {
                                    disabled: !_vm.valid,
                                    large: "",
                                    rounded: "",
                                    color: "success",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.login },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        color: "white",
                                        "margin-bottom": "0px",
                                      },
                                    },
                                    [_vm._v("Criar nova conta")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }