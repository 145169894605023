<template>
  <v-app>
    <v-container id="auth" class="fill-height">
      <v-row justify="center">
        <v-slide-y-transition appear>
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Register</h1>
            </div>
          </template>

          <v-card class="card">
            <figure class="btn-back">
              <img
                src="@/assets/icons/arrow_back_ios.svg"
                alt="Botão voltar"
                @click="goBack"
              />
            </figure>

            <v-card-title class="justify-center title-card">
              <h2>Crie uma conta!</h2>
            </v-card-title>

            <v-card-text class="text-center">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="flex-column" align="start">
                  <v-col class="d-flex flex-column align-start py-0">
                    <span class="text-filelds">Nome</span>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="name"
                      placeholder="Jacob Jones"
                      :rules="[(v) => !!v || 'Porfavor, insira seu nome']"      
                      color="secondary"
                      required
                      outlined
                      rounded
                      class="input"
                    />
                  </v-col>
                </v-row>

                <v-row class="flex-column tipo-cliente" align="start">
                  <v-col class="d-flex flex-column align-start py-0">
                    <span class="text-filelds">Você é um:</span>
                  </v-col>
                  <v-radio-group v-model="tipoInvestidor" row>
                    <v-radio
                      name="active"
                      label="Investidor"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      name="active"
                      label="Profissional"
                      :value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-row>

                <v-row class="flex-column" align="start">
                  <v-col class="d-flex flex-column align-start py-0">
                    <span class="text-filelds">E-mail</span>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      placeholder="willie.jennings@example.com"
                      :rules="[(v) => !!v || 'E-mail requerido']"
                      color="secondary"
                      required
                      outlined
                      rounded
                      class="input"
                    />
                  </v-col>
                </v-row>

                <v-row class="flex-column senha" align="start">
                  <v-col class="d-flex flex-column align-start py-0">
                    <span class="text-filelds">Senha</span>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="password"
                      placeholder="********"
                      :rules="passwordRules"
                      color="secondary"
                      required
                      outlined
                      rounded
                      class="input"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <div class="mensagem-sucesso" v-if="success">
                <span>Usuário criado com sucesso</span>
              </div>

              <v-row v-if="!success">
                <v-col>
                  <div class="condicoes">
                    <input
                      type="checkbox"
                      name="s"
                      id="checkBox"
                      class="checkBox"
                      v-model="termosCondicoesLidos"
                      @click="!termosCondicoesLidos"
                    />
                    <span>
                      Eu li e concordocom os
                      <span class="termos-condicoes">Termos e Condições</span>
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                v-if="!success"
                :disabled="!valid"
                large
                rounded
                color="success"
                @click="login"
                :loading="loading"
                class="my-4 btn-login py-3"
              >
                <p style="color: white; margin-bottom: 0px">Criar nova conta</p>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
#auth {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--principal-azul);
  max-width: unset;
}
.mensagem-sucesso {
  color: rgb(48, 184, 48);
}
.btn-back {
  display: flex;
  cursor: pointer;
}
.title-card {
  font-size: 0.8rem;
}
.title-card > h2 {
  font-weight: 500 !important;
}

.text-filelds {
  font-size: 1.2em;
}
.card {
  padding: 2.5rem 1.5rem 1.5rem !important;
  width: 450px;
  height: auto;
}
.condicoes {
  margin-top: 0;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.termos-condicoes {
  border-bottom: 1px solid;
  cursor: pointer;
}
.condicoes .checkBox {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  margin: 0 0.5rem;
}

.tipo-cliente {
  margin-top: 0;
}
.register {
  font-size: 13px;
  font-weight: 300;
}
.register-btn {
  color: var(--principal-azul);
  cursor: pointer;
}
.forget-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
.forget-button {
  transition: 500ms;
}

.forget-button:hover {
  font-size: 0.8rem;

  transition: 500ms;
}

.senha {
  margin-top: 0 !important;
}
</style>

<script>
import ApiService from "../../../services/ApiService";

export default {
  name: "Register",

  components: {},

  data: () => ({
    show: false,
    success: false,
    row: undefined,
    apiService: new ApiService(),
    loading: false,
    error: false,
    valid: true,
    documentNumber: "",
    documentNumberRules: [(v) => !!v || "CPF requerido"],
    password: "",
    passwordRules: [(v) => !!v || "Senha requerida"],
    name: undefined,
    password: undefined,
    email: undefined,
    tipoInvestidor: undefined,
    termosCondicoesLidos: false,
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    goBack() {
      this.$router.go(-1);
    },
    toForgot() {
      this.$router.push("/auth/forgot-password");
    },
    toRegister() {
      this.$router.push("/auth/register");
    },

    async login() {
      if (this.validate() && this.termosCondicoesLidos) {
        const user = {
          Name: this.name,
          Password: this.password,
          Email: this.email,
          TipoInvestidor: this.tipoInvestidor,
        };

        this.loading = true;

        try {
          let req = await this.apiService.post("user/register", user);

          if (req.statusCode === 200) {
            this.success = true;

            setTimeout(() => {
              this.$router.push("/auth");
            }, 4000);

            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          this.$toast.error('Erro ao tentar registar')
        }
      }
    },
  },
};
</script>
